<template>
  <div>
    <v-dialog
      v-model="helpDialog"
      width="500">
      <v-card>
        <v-card-title class="headline">
          Helper
        </v-card-title>
        <v-card-text>{{ helpText }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="helpDialog = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row
      v-for="(material, index) in innerVal"
      :key="`material-${index}`">
      <v-col cols="6">
        <gw-drop-file
          v-model="material.images"
          :multiple="true"
          need-compress
          label="Material"
          class="material-drop-file"
          s3-path="/sketch-images"
          caption="คลิกเพื่อเพิ่มรูปภาพ"
          label-bold />
      </v-col>
      <v-col cols="6">
        <v-row class="mt-5">
          <v-col cols="12">
            <v-text-field
              v-model="material.name"
              label="Name"
              hide-details
              outlined
              dense
              class="mr-2" />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="material.note"
              label="Note"
              dense
              hide-details
              outlined />
          </v-col>
          <v-col
            cols="12"
            class="d-flex">
            <v-text-field
              v-model.number="material.quantity"
              class="material-amount-input mr-2"
              label="Quantity"
              outlined
              dense
              persistent-hint
              :hint="`ค่าเดิม = ${material.lastQty}`"
              @keypress="$keypressNumber($event)" />
            <v-text-field
              v-if="!disableUnitPrice"
              v-model.number="material.unitPrice"
              label="Unit Price"
              outlined
              hide-details
              dense
              class="mr-2"
              @keypress="$keypressNumber($event)" />
            <v-btn
              v-if="innerVal.length > 1"
              color="error"
              class="delete-material-btn"
              @click="deleteMaterial(index)">
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-btn
      class="mt-2"
      small
      @click="addMoreMaterial()">
      <v-icon left>
        mdi-plus
      </v-icon>
      add more material
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disableUnitPrice: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      helpDialog: false,
      helpText: ''
    }
  },
  computed: {
    innerVal: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },
  methods: {
    addMoreMaterial () {
      this.innerVal.push({
        images: [],
        note: '',
        unitPrice: 0,
        quantity: 0,
        lastQty: 0,
        name: ''
      })
    },
    deleteMaterial (index) {
      this.innerVal.splice(index, 1)
    }
  }
}
</script>

<style>
.qty-field {
  width: 100%;
}

.material-drop-file .upload-file {
  height: 258px;
}

.delete-material-btn.v-btn {
  height: 40px !important;
}

.field-label {
  font-weight: bold;
}

.material-amount-input .v-messages__message {
  margin-top: 5px;
  font-size: 15px;
}
</style>
