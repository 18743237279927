import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PrototypeProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_OLD_API)
  }

  getManyWithQuery (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/prototypes/sketch', query)
  }

  getManySketches (query) {
    this.setHeader(getAuthToken())
    return this.getByPagination('/prototypes/sketch', query)
  }

  getPrototypes (query) {
    this.setHeader(getAuthToken())
    return this.get('/prototypes/order/mapping', query)
  }

  getPrototypeBySketchId (sketchId) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/${sketchId}/sketch-id`)
  }

  getPrototypeByProductId (productId) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/mapping/${productId}`)
  }

  getPrototypeByProductFactoryId (productFactoryId) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/${productFactoryId}/product-factory-id`)
  }

  getOneSketchById (sketchId) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/sketch/${sketchId}`)
  }

  getSketchBySketchId (sketchId) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/sketch/${sketchId}/sketch-id`)
  }

  createSketch (sketchForm) {
    this.setHeader(getAuthToken())
    return this.post('/prototypes/sketch', sketchForm)
  }

  updateSketch (sketchId, sketchForm) {
    this.setHeader(getAuthToken())
    return this.put(`/prototypes/sketch/${sketchId}`, sketchForm)
  }

  deleteSketch (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/prototypes/${id}`)
  }

  editOrder (id, prototype) {
    this.setHeader(getAuthToken())
    return this.put(`/prototypes/order/${id}`, prototype)
  }

  createOrder (ids) {
    this.setHeader(getAuthToken())
    return this.post('/prototypes/order', ids)
  }

  getOneOrderById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/prototypes/order/${id}`)
  }

  createManufacturing (ids) {
    this.setHeader(getAuthToken())
    return this.post('/prototypes/manufacture', ids)
  }

  changeState (ids, state) {
    this.setHeader(getAuthToken())
    return this.patch('/prototypes', { ids, state })
  }

  changeManufactureStatus (ids, state) {
    this.setHeader(getAuthToken())
    return this.patch('/prototypes/manufacture/changes-status', { ids, state })
  }

  mapProduct (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/prototypes/${payload.id}/mapping-product`, payload)
  }
}

export default PrototypeProvider
