import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PrototypeCategoryProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_MER_API)
  }

  getAll () {
    this.setHeader(getAuthToken())
    return this.get('/prototype-categories')
  }

  getAllMapped () {
    this.setHeader(getAuthToken())
    return this.get('/prototype-categories/mapped')
  }

  getOne (id) {
    this.setHeader(getAuthToken())
    return this.get(`/prototype-categories/${id}`)
  }

  createOne (form) {
    this.setHeader(getAuthToken())
    return this.post('/prototype-categories', form)
  }

  updateOne (id, form) {
    this.setHeader(getAuthToken())
    return this.post(`/prototype-categories/${id}`, form)
  }

  deleteOne (id) {
    this.setHeader(getAuthToken())
    return this.get(`/prototype-categories/${id}`)
  }
}

export default PrototypeCategoryProvider
