<template>
  <section
    id="createSketch"
    class="d-flex justify-center">
    <v-form
      ref="sketchForm"
      v-model="formValid"
      lazy-validation
      class="create-sketch-content-box">
      <v-row>
        <v-col cols="12">
          <h1 class="title">
            {{ pageTitle }}
          </h1>
        </v-col>
      </v-row>
      <v-row class="create-sketch-form">
        <v-col cols="12">
          <p class="field-label mb-n5">
            Sketch Picture
          </p>
          <gw-drop-file
            v-model="form.images"
            :show-button="form.images.length > 0"
            :multiple="true"
            need-compress
            s3-path="/sketch-images"
            caption="คลิกเพื่อเพิ่มรูปภาพ" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="12">
          <material-stage-old
            v-model="form.materials"
            :disable-unit-price="true" />
        </v-col>
        <v-col cols="12">
          <v-divider />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="form.sketchId"
            outlined
            label="Sketch ID"
            hide-details />
        </v-col>
        <v-col cols="6">
          <tree-select
            v-model="form.category"
            :items="selectOptions.categories"
            label="Product Types"
            item-text="name"
            item-value="id"
            sub-property="children"
            return-object
            hide-details
            :rules="textBoxRules" />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.brand"
            :items="selectOptions.brands"
            item-text="name"
            item-value="name"
            :menu-props="{ offsetY: true }"
            label="Brand"
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.factory"
            :items="selectOptions.factories"
            :menu-props="{ offsetY: true }"
            label="Factory"
            item-text="name"
            return-object
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.designer"
            :items="selectOptions.designers"
            :menu-props="{ offsetY: true }"
            label="Designer"
            item-text="name"
            item-value="name"
            return-object
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.merchandiser"
            :items="selectOptions.merchandisers"
            :menu-props="{ offsetY: true }"
            label="Merchandiser"
            item-text="name"
            item-value="name"
            return-object
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.graphic"
            :items="selectOptions.graphics"
            :menu-props="{ offsetY: true }"
            label="Graphic"
            item-text="name"
            item-value="name"
            return-object
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.stylist"
            :items="selectOptions.stylists"
            :menu-props="{ offsetY: true }"
            label="Creative"
            item-text="name"
            item-value="name"
            return-object
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            v-model="form.collectionName"
            label="Collection"
            color="secondary"
            :rules="textBoxRules"
            :items="selectOptions.collections"
            item-text="name"
            item-value="name"
            outlined
            hide-details />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="form.classification"
            label="Classification"
            color="secondary"
            :rules="textBoxRules"
            :items="selectOptions.classifications"
            outlined
            hide-details />
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="form.note"
            label="Note"
            outlined />
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="sizeDetail"
            :items="sizes"
            :rules="sizeDetailRules"
            label="Size"
            multiple
            outlined
            deletable-chips
            :return-object="false"
            small-chips
            dense />
        </v-col>
        <v-col cols="12">
          <sizing-detail
            v-model="form.sizeDetail"
            :size-unit.sync="form.sizeUnit" />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center">
          <v-btn
            color="secondary"
            :disabled="!formValid"
            @click="tryToCreateSketch()">
            {{ submitText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </section>
</template>

<script>
import { prototypeData } from '@/assets/js/DefaultPrototypeData'
import { mapActions, mapGetters } from 'vuex'
import SketchProviderOld from '@/resources/SketchProviderOld'
import MerchandiserProvider from '@/resources/MerchandiserProvider'
import DesignerProvider from '@/resources/DesignerProvider'
import GraphicProvider from '@/resources/GraphicProvider'
import StylistProvider from '@/resources/StylistProvider'
import FactoryProvider from '@/resources/FactoryProvider'
import PrototypeCategoryProvider from '@/resources/PrototypeCategoryProvider'
import PrototypeProviderOld from '@/resources/PrototypeProviderOld'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import MaterialStageOld from '../components/MaterialStageOld.vue'
import SizingDetail from '../components/SizingDetail.vue'
import TreeSelect from '../components/TreeSelect.vue'

const SketchService = new SketchProviderOld()
const MerchandiserService = new MerchandiserProvider()
const DesignerService = new DesignerProvider()
const GraphicService = new GraphicProvider()
const StylistService = new StylistProvider()
const FactoryService = new FactoryProvider()
const PrototypeCategoryService = new PrototypeCategoryProvider()
const PrototypeService = new PrototypeProviderOld()
const ProductAttributeService = new ProductAttributeProvider()

export default {
  components: {
    MaterialStageOld,
    SizingDetail,
    TreeSelect
  },
  data () {
    return {
      form: {
        sketchNo: 0,
        images: [],
        materials: [{
          name: '',
          images: [],
          note: '',
          quantity: '',
          lastQty: 0
        }],
        sketchId: '',
        designer: {
          id: '',
          name: ''
        },
        factory: {
          id: '',
          name: ''
        },
        category: {
          id: null,
          name: '',
          parentId: null
        },
        merchandiser: {
          id: '',
          name: ''
        },
        graphic: {
          id: '',
          name: ''
        },
        stylist: {
          id: '',
          name: ''
        },
        collectionName: '',
        classification: '',
        note: '',
        sizeDetail: [],
        sizeUnit: '',
        model: '',
        brand: ''
      },
      selectOptions: {
        designers: [],
        factories: [],
        merchandisers: [],
        graphics: [],
        stylists: [],
        categories: [],
        size: [],
        brands: [],
        collections: [],
        classifications: ['Core', 'Directional', 'Essential']
      },
      rawCategories: [],
      oldValue: null,
      textBoxRules: [
        (v) => !!v || 'Field is require!'
      ],
      sizeDetailRules: [
        // (v) => this.sizeDetailUpperCaseRules(v)
      ],
      formValid: false,
      isEdit: false
    }
  },
  computed: {
    ...mapGetters({
      recentlyRoute: 'Route/recentlyRoute'
    }),
    isRemakeSketch () {
      return !!this.$route?.params?.remake
    },
    hasParamId () {
      return !!this.$route?.params?.id
    },
    pageTitle () {
      return this.hasParamId ? 'Edit Sketch' : 'Create Sketch'
    },
    submitText () {
      return this.hasParamId ? 'SAVE' : 'CREATE'
    },
    sizeDetail: {
      get () {
        return this.form.sizeDetail.map((item) => item.size.trim())
      },
      set (newVal) {
        const newArr = [...new Set(newVal)].filter((s) => this.sizes.includes(s))
        this.setSizeDetail(newArr)
      }
    },
    sizes () {
      return this.selectOptions.size.map((s) => s.name)
    }
  },
  async mounted () {
    await this.initSelectOptions()

    if (this.$route?.params?.id) {
      this.isEdit = true
      this.getSketch(this.$route.params.id)
    }

    this.oldValue = JSON.parse(JSON.stringify(this.form))
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setDarkMode: 'Style/setDarkMode',
      setIsWebpSupported: 'Style/setIsWebpSupported',
      initStore: 'Store/initStore'
    }),
    setSizeDetail (newArr) {
      if (newArr.length >= this.form.sizeDetail.length) {
        newArr.forEach((size) => {
          const findIndex = this.form.sizeDetail.findIndex((item) => item.size === size)
          if (this.form.sizeDetail.length > 0 && findIndex !== -1) {
            this.form.sizeDetail.splice(findIndex, 1, {
              ...this.form.sizeDetail[findIndex]
            })
          } else if (this.form.sizeDetail.length > 0) {
            this.form.sizeDetail.push({
              options: this.form.sizeDetail[0].options.map((option) => ({
                key: option?.key || '',
                value: ''
              })),
              size
            })
          } else {
            this.form.sizeDetail.push({
              options: [{
                key: '',
                value: ''
              }],
              size
            })
          }
        })
      } else {
        this.form.sizeDetail.forEach((item, index) => {
          const findIndex = newArr.findIndex((size) => item.size === size)
          if (findIndex === -1) {
            this.form.sizeDetail.splice(index, 1)
          }
        })
      }
    },
    sizeDetailUpperCaseRules (value) {
      let data = true
      for (let i = 0; i < value.length; i++) {
        if (value[i] !== value[i].toUpperCase() || value[i].trim() === '' || value[i] !== value[i].trim()) {
          data = false
          break
        }
      }
      return data || 'Require to uppercase letter and no space.'
    },
    async getSketch (id) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING SKETCHES...'
        })
        const { data } = await SketchService.getOneSketchById(id)
        const sketch = data?.sketch ? data.sketch : {}

        this.form = {
          ...prototypeData.sketch,
          ...sketch,
          collectionName: sketch?.collectionName || '',
          classification: sketch?.classification || ''
        }

        const materials = this.form?.materials?.length > 0 ? this.form.materials : [{
          images: [],
          note: '',
          unitPrice: 0,
          quantity: 0,
          lastQty: 0,
          name: ''
        }]

        this.form.materials = materials.map((m) => ({ ...m, lastQty: m.quantity }))
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async initSelectOptions () {
      try {
        this.setLoading({
          active: true,
          clickAble: false
        })
        const promised = await Promise.all([
          MerchandiserService.getAll(),
          DesignerService.getAll(),
          GraphicService.getAll(),
          StylistService.getAll(),
          FactoryService.getAll(),
          PrototypeCategoryService.getAllMapped(),
          ProductAttributeService.getAllProductAttribute()
        ])
        this.selectOptions.merchandisers = promised[0].data.results.map((item) => ({ id: item.id, name: item.name }))
        this.selectOptions.designers = promised[1].data.results.map((item) => ({ id: item.id, name: item.name }))
        this.selectOptions.graphics = promised[2].data.results.map((item) => ({ id: item.id, name: item.name }))
        this.selectOptions.stylists = promised[3].data.results.map((item) => ({ id: item.id, name: item.name }))
        this.selectOptions.factories = promised[4].data.results
          .map((item) => ({ id: item.id, name: item.name }))
          .sort((a, b) => a.id - b.id)
        this.selectOptions.categories = promised[5].data.map((d) => ({
          ...d,
          disabled: d.name === 'UNKNOWN'
        }))
        this.selectOptions.size = promised[6].data.size ? promised[6].data.size.sort((a, b) => a.id - b.id) : []
        this.selectOptions.brands = promised[6].data.brands ? promised[6].data.brands : []
        this.selectOptions.collections = promised[6].data.collections ? promised[6].data.collections : []
      } catch (error) {
        console.error('initSelectOptions', error)
        this.setSnackbar({
          value: true,
          message: `ERROR WHILE INIT OPTIONS: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async updateSketch () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING SKETCHES...'
        })
        const { data: existed } = await PrototypeService.getPrototypeBySketchId(this.form?.sketchId)

        if (existed && existed.id !== this.$route?.params?.id) {
          const error = {
            code: 400,
            message: `Duplicated sketch id (${this.form?.sketchId})` }
          throw error
        }

        const { data: { id } } = await SketchService.updateSketch(this.$route?.params?.id, this.form)

        if (id) {
          this.setSnackbar({
            value: true,
            message: 'Prototype has been updated',
            type: 'success'
          })
          this.redirectRoute('update')
        } else {
          const error = {
            code: 400,
            message: 'Something was wrong.'
          }
          throw error
        }
      } catch (error) {
        console.error('updateSketch', error)
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    tryToCreateSketch () {
      if ((JSON.stringify(this.form) !== JSON.stringify(this.oldValue)) && (!this.hasParamId || this.isRemakeSketch)) {
        this.createSketch()
      } else if ((JSON.stringify(this.form) !== JSON.stringify(this.oldValue)) && this.hasParamId && !this.isRemakeSketch) {
        this.updateSketch()
      } else {
        this.setSnackbar({
          value: true,
          message: 'กรุณากรอกข้อมูล',
          type: 'error'
        })
      }
    },
    async createSketch () {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING SKETCHES...'
        })

        const { data: existed } = await PrototypeService.getPrototypeBySketchId(this.form?.sketchId)

        if (existed) {
          const error = {
            code: 400,
            message: `Duplicated sketch id (${this.form?.sketchId})` }
          throw error
        }

        const { data } = await SketchService.createSketch(this.removeUnuseData(this.form))
        if (data.id) {
          this.redirectRoute('create')
        } else {
          const error = {
            code: 400,
            message: 'Something was wrong.'
          }
          throw error
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `${error.code}: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    redirectRoute (status) {
      if (status === 'update' && this.recentlyRoute?.name === 'SketchListOld') {
        this.$router.push(this.recentlyRoute)
      } else {
        this.$router.push({ name: 'SketchListOld' })
      }
    },
    objectEqual (object1, object2) {
      const keys1 = Object.keys(object1)
      const keys2 = Object.keys(object2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (const key of keys1) {
        const val1 = object1[key]
        const val2 = object2[key]
        const areObjects = this.isObject(val1) && this.isObject(val2)
        if ((areObjects && !this.objectEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
          return false
        }
      }

      return true
    },
    isObject (object) {
      return object != null && typeof object === 'object'
    },
    removeUnuseData (_obj) {
      const obj = _obj
      const keys = Object.keys(obj)
      keys.forEach((key) => {
        if (key !== 'options') {
          if (obj[key] && (this.isObject(obj[key]) && JSON.stringify(obj[key]) !== '{}')) {
            obj[key] = this.removeUnuseData(obj[key])
          } else if (!obj[key] || JSON.stringify(obj[key]) === '{}') {
            delete obj[key]
          }
        }
      })

      return JSON.stringify(obj) !== '{}' ? obj : null
    }
  }
}
</script>

<style scoped>
.create-sketch-content-box {
  padding: 15px 15px;
  background-color: #fff;
  /* max-width: 1030px; */
}

.create-sketch-form {
  margin: 16px 0px;
}

.field-label {
  font-weight: bold;
}
</style>
